import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { getCooki, userHeader } from "../../../../shared/cooki";
import { useLocation, useNavigate } from "react-router-dom";
import { dateToCalenderFormat } from "../../../../shared/functions/dateConverter";
import { configContext, userContext } from "../../../../App";
import getImageUrl from "../../../../shared/functions/getImageUrl";
import { FaRegImage } from "react-icons/fa";
import { MdOutlineEdit } from "react-icons/md";
import FailedTost from "../../../../shared/components/FailedTost/FailedTost";
import SuccessTost from "../../../../shared/components/SuccessTost/SuccessTost";

const colorArray = [
  "#e74c3c",
  "#3498db",
  "#2ecc71",
  "#f39c12",
  "#5F4B8BFF",
  "#F95700FF",
  "#D6ED17FF",
  "#2C5F2D",
  "#0063B2FF",
  "#2BAE66FF",
];
const cooki = getCooki();

const AdminAddDailyTask = () => {
  const [input, setInput] = useState({ autoApprove: true });
  const [configInput, setConfigInput] = useState({});
  const [condition, setCondition] = useState({ btnLoading: false });
  const [activeTab, setActiveTab] = useState("edit-profile");
  const [currentStyle, setCurrentStyle] = useState("");
  const [coinArray, setCoinArray] = useState([
    {
      coin: "",
      percentage: "",
      maxCount: "",
    },
  ]);
  const [config, setConfig] = useContext(configContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useContext(userContext);

  const queryParams = new URLSearchParams(location.search);
  const dailyTaskID = queryParams.get("dailyTaskID");

  useEffect(() => {
    if (activeTab === "edit-profile") {
      setInput({
        firstName: user.firstName,
        lastName: user.lastName,
        _id: user._id,
      });
    } else {
      setInput({});
    }
  }, [activeTab, user]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name === "img") {
      return setInput((state) => {
        return {
          ...state,
          imageUpdate: true,
          [name]: e.target.files[0],
        };
      });
    }
    if (name === "autoApprove") {
      return setInput((state) => {
        return {
          ...state,
          [name]: e.target.checked,
        };
      });
    }

    setInput((state) => {
      return {
        ...state,
        [name]: value,
      };
    });
  };
  const handleConfigChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setConfigInput((state) => {
      return {
        ...state,
        [name]: value,
      };
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("img", input.img);
    formData.append("data", JSON.stringify(input));
    setCondition((state) => {
      return {
        ...state,
        btnLoading: true,
      };
    });
    let endPoint = "/daily-task/admin-create-task";

    if (dailyTaskID) {
      endPoint = "/daily-task/admin-edit-task";
    }

    fetch(`${process.env.REACT_APP_SERVER_HOST_URL}${endPoint}`, {
      method: "POST",
      body: formData,
    })
      .then((data) => data.json())
      .then((data) => {
        console.log("data ===>>>", data);
        if (data.data) {
          navigate(-1);
          setInput({});
        }
      })
      .finally(() => {
        setCondition((state) => {
          return {
            ...state,
            btnLoading: false,
          };
        });
      });
  };
  const onRewardsSubmit = async (e) => {
    e.preventDefault();
    let totalPercentage = 0;

    await coinArray.forEach((item) => {
      if (Number(item?.percentage)) {
        totalPercentage = totalPercentage + Number(item.percentage);
      }
    });
    if (totalPercentage !== 100) {
      return;
    }
    const taskRewardsList = await coinArray.filter(
      (item) => item?.percentage > 1
    );

    const response = await fetch(
      `${process.env.REACT_APP_SERVER_HOST_URL}/daily-task/set-config`,
      {
        method: "POST",
        body: JSON.stringify({ taskRewardsList: taskRewardsList }),
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          authorization: `Bearer ${cooki}`,
        },
      }
    );
    const data = await response.json();
    if (data.data) {
      setConfig(data.data);
    }
  };

  const handleSetCoin = (e, inputIndex) => {
    const name = e.target.name;
    const value = e.target.value;

    if (name !== "coin" && name !== "maxCount" && value && !Number(value)) {
      return;
    }

    let currentPercentage = Number(value) || 0;
    console.log("currentPercentage 0 =>>", currentPercentage);
    coinArray.forEach(({ coin, percentage }, index) => {
      console.log("percentage =>", percentage);
      if (index !== inputIndex) {
        currentPercentage = currentPercentage + percentage;
      }
    });
    console.log("currentPercentage 1 =>", currentPercentage);
    if (name !== "coin" && name !== "maxCount" && currentPercentage > 100) {
      return;
    }

    const updateArray = coinArray.map((obj, index) => {
      if (index === inputIndex) {
        return {
          ...obj,
          [name]: Number(value),
        };
      }
      return obj;
    });
    setCoinArray(updateArray);
  };
  const handleProfileImgSubmit = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();

    formData.append("img", file);

    fetch(`${process.env.REACT_APP_SERVER_HOST_URL}/profile/profile-pic`, {
      method: "POST",
      headers: {
        ...userHeader(),
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("data ==>>", data);
        if (data.data) {
          setUser(data.data);
          SuccessTost("Profile picture has been uploaded successfully");
        } else if (data.message) {
          FailedTost(data.message);
        }
      });
  };

  const userUpdateHandle = (e) => {
    e.preventDefault();
    if (input.firstName && input.lastName) {
      setCondition((state) => {
        return {
          ...state,
          btnLoading: true,
        };
      });
      fetch(`${process.env.REACT_APP_SERVER_HOST_URL}/user`, {
        method: "PATCH",
        body: JSON.stringify(input),
        headers: {
          "content-type": "application/json; charset=UTF-8",
          authorization: `Bearer ${cooki}`,
        },
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.data) {
            const updatedUser = { ...data.data };
            setUser({ ...user, ...updatedUser });
          }
          if (data.message?.sucess) {
            SuccessTost(data.message.sucess);
          }
          if (data.message?.failed) {
            SuccessTost(data.message.failed);
          }
        })
        .finally(() => {
          setCondition((state) => {
            return {
              ...state,
              btnLoading: false,
            };
          });
        });
    }
  };
  const userPasswordChangeHandle = (e) => {
    e.preventDefault();
    if (input.oldPassword && input.newPassword && input.confirmPassword) {
      if (input.newPassword == input.confirmPassword) {
        fetch(`${process.env.REACT_APP_SERVER_HOST_URL}/passwordReset`, {
          method: "PATCH",
          body: JSON.stringify(input),
          headers: {
            "content-type": "application/json; charset=UTF-8",
            ...userHeader()
          },
        })
          .then((res) => res.json())
          .then((data) => {
            if (data.failed) {
              FailedTost(data.failed)
            }else{
              SuccessTost("Your password has been reset successfully")
            }

          });
      } else {
        FailedTost("Confirm password doesn't match, please try again");
      }
    }
  };

  return (
    <div className="admin-add-daily-task">
      <div className="header-section">
        <div className="user-profile">
          <div className="picture-container">
            {user.profilePicture && (
              <img src={getImageUrl(user.profilePicture)} alt="" />
            )}
            {!user.profilePicture && <FaRegImage />}
            <input
              type="file"
              id="fileUpload"
              onChange={handleProfileImgSubmit}
            />
          </div>
          <div className="info-container">
            <p className="name">{`${user?.firstName} ${user?.lastName}`} </p>
          </div>
        </div>
      </div>
      <div className="header-section">
        <button
          className={activeTab === "edit-profile" ? "active" : ""}
          onClick={() => setActiveTab("edit-profile")}
        >
          Edit Profile
        </button>
        <button
          className={activeTab === "reset-password" ? "active" : ""}
          onClick={() => setActiveTab("reset-password")}
        >
          Reset Password
        </button>
      </div>

      {activeTab === "edit-profile" ? (
        <div className="wrap-contact2">
          <form className="contact2-form validate-form">
            <div className="validate-input">
              <input
                className={`input2 ${input.firstName ? "fill" : ""}`}
                type="text"
                value={input.firstName || ""}
                name="firstName"
                onChange={handleChange}
              />
              <span className="focus-input2">Fist Name</span>
            </div>
            <div className="validate-input">
              <input
                className={`input2 ${input.lastName ? "fill" : ""}`}
                type="text"
                value={input.lastName || ""}
                name="lastName"
                onChange={handleChange}
              />
              <span className="focus-input2">Last Name</span>
            </div>

            <div className="container-contact2-form-btn">
              <button
                type="submit"
                disabled={condition?.btnLoading}
                onClick={userUpdateHandle}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="wrap-contact2">
          <form className="contact2-form validate-form">
            <div className="validate-input">
              <input
                className={`input2 ${input.oldPassword ? "fill" : ""}`}
                type="text"
                value={input.oldPassword || ""}
                name="oldPassword"
                onChange={handleChange}
              />
              <span className="focus-input2">Old Password</span>
            </div>

            <div className="validate-input">
              <input
                className={`input2 ${input.newPassword ? "fill" : ""}`}
                type="text"
                value={input.newPassword || ""}
                name="newPassword"
                onChange={handleChange}
              />
              <span className="focus-input2">New Password</span>
            </div>
            <div className="validate-input">
              <input
                className={`input2 ${input.confirmPassword ? "fill" : ""}`}
                type="text"
                value={input.confirmPassword || ""}
                name="confirmPassword"
                onChange={handleChange}
              />
              <span className="focus-input2">Confirm Password</span>
            </div>

            <div className="container-contact2-form-btn">
              <button
                type="submit"
                disabled={condition?.btnLoading}
                onClick={userPasswordChangeHandle}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AdminAddDailyTask;
